import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';

export default ({ onSignOut }) => {
	const authReducer = useSelector((state) => state.authReducer);

	return (
		<Menu theme="dark" mode="horizontal">
			<Menu.Item key="1">Hi! {authReducer.username}</Menu.Item>
			<Menu.Item key="2" onClick={onSignOut}>Sign Out</Menu.Item>
		</Menu>
	);
};
