const initState = {
	vods: []
}

export default (state = initState, action) => {
	switch (action.type) {
		case 'GET_VODS': {
			return {
				...state,
				vods: action.payload
			}
		}
		default:
			return state
	}
};