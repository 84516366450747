const initState = {
	isSignIn: false,
	username: '',
	email: '',
	emailVerified: false,
	idToken: {
		jwtToken: '',
		expiry: null
	},
	refreshToken: '',
	accessToken: {
		jwtToken: '',
		expiry: null
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case 'SIGN_IN': {
			return {
				...state,
				isSignIn: true,
				username: action.payload.username,
				email: action.payload.attributes.email,
				emailVerified: action.payload.attributes.email_verified,
				idToken: {
					jwtToken: action.payload.signInUserSession.idToken.jwtToken,
					expiry: action.payload.signInUserSession.idToken.payload.exp
				},
				refreshToken: action.payload.signInUserSession.refreshToken.token,
				accessToken: {
					jwtToken: action.payload.signInUserSession.accessToken.jwtToken,
					expiry: action.payload.signInUserSession.accessToken.payload.exp
				}
			}
		}
		case 'SIGN_UP': {
			return {
				...state,
				username: action.payload.username
			}
		}
		case 'CONFIRM_SIGN_UP': {
			return {
				...state,
				username: '',
				email: ''
			}
		}
		case 'SIGN_OUT': {
			return initState;
		}
		default:
			return state
	}
};