import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Storage } from 'aws-amplify';
import axios from 'axios';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Layout, Upload, List, Row, Col } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Dragger } = Upload;
const ScrollCol = styled(Col)`
	height: 350px;
	overflow-y: scroll;
`;
const FixHeightRow = styled(Row)`
	height: 350px;
`;

const Home = () => {
	const authReducer = useSelector((state) => state.authReducer);
	const vodReducer = useSelector((state) => state.vodReducer);
	const dispatch = useDispatch();

	const [vodUrl, setVodUrl] = useState('');

	const props = {
		name: 'file',
		multiple: true,
		onStart(file) {
			console.log('onStart', file, file.name);
		},
		onSuccess(ret, file) {
			console.log('onSuccess', ret, file.name);
		},
		onError(err) {
			console.log('onError', err);
		},
		onProgress({ percent }, file) {
			console.log('onProgress', `${percent}%`, file.name);
		},
		customRequest({ file, onError, onProgress, onSuccess }) {
			Storage.put(authReducer.username + '/' + file.name, file, {
				bucket: 'vod-upload-watching',
				level: 'public',
				progressCallback(progress) {
					onProgress(
						{
							percent: Math.round(
								(progress.loaded / progress.total) * 100
							).toFixed(2),
						},
						file
					);
				},
			})
				.then((result) => {
					onSuccess(result, file);
				})
				.catch(onError);

			return {
				abort() {
					console.log('upload progress is aborted.');
				},
			};
		},
	};

	useEffect(() => {
		axios
			.get(
				'https://18tdbvtavh.execute-api.ap-northeast-2.amazonaws.com/getVodList',
				{
					headers: {
						Authorization: authReducer.accessToken.jwtToken,
					},
					params: {
						username: authReducer.username,
					},
				}
			)
			.then(async (response) => {
				dispatch({
					type: 'GET_VODS',
					payload: response.data.Items,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	// Storage.list('', { bucket: 'vod-content-storage', level: 'public' })
	// 	.then((result) => console.log(result))
	// 	.catch((err) => console.log(err));

	return (
		<Layout style={{ padding: '0 24px 24px' }}>
			<Content
				className="site-layout-background"
				style={{
					padding: 24,
					margin: 0,
					minHeight: 280,
				}}
			>
				<FixHeightRow>
					<ScrollCol span={12}>
						<List
							itemLayout="vertical"
							size="large"
							pagination={{
								onChange: (page) => {
									console.log(page);
								},
								pageSize: 5,
							}}
							dataSource={vodReducer.vods}
							renderItem={(item) => (
								<List.Item
									key={item.filename}
									extra={
										<img
											width={120}
											alt={item.filename}
											src={item.detail[1].outputDetails[0].outputFilePaths[0].replace(
												's3://vod-content-storage',
												'https://18tdbvtavh.execute-api.ap-northeast-2.amazonaws.com/getVodChunk'
											)}
										/>
									}
								>
									<List.Item.Meta
										title={item.filename}
										onClick={() => setVodUrl(
											item.detail[0].playlistFilePaths[0].replace(
												's3://vod-content-storage',
												'https://18tdbvtavh.execute-api.ap-northeast-2.amazonaws.com/getVodChunk'
											)
										)}
									/>
									<ul>
										{item.detail[0].outputDetails.map((vod, index) => (
											<li
												key={vod.outputFilePaths[0]}
												onClick={() => setVodUrl(
													vod.outputFilePaths[0].replace(
														's3://vod-content-storage',
														'https://18tdbvtavh.execute-api.ap-northeast-2.amazonaws.com/getVodChunk'
													)
												)}
											>
												{vod.videoDetails.widthInPx +
													' X ' +
													vod.videoDetails.heightInPx}
											</li>
										))}
									</ul>
								</List.Item>
							)}
						/>
					</ScrollCol>
					<Col span={12}>
						<Dragger {...props}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
							<p className="ant-upload-hint">
								Support for a single or bulk upload. Strictly prohibit from
								uploading company data or other band files
							</p>
						</Dragger>
					</Col>
				</FixHeightRow>
				<Row>
					<Col span={24}>
						<ReactPlayer url={vodUrl} controls width="1920" height="1080" />
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default Home;
