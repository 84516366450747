import React, { useState } from 'react';
import { Button, Row, Input, Form } from 'antd';

import { AuthForm } from '../../styles/Auth';

const FormItem = Form.Item;

const FindPassword = ({ onSignIn, onToggleSignInUp }) => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	return (
		<>
			<AuthForm>
				<Form onFinish={() => onSignIn(username, password)}>
					<FormItem name="username" rules={[{ required: true }]} hasFeedback>
						<Input
							type="email"
							placeholder="Username"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</FormItem>
					<Row>
						<Button type="primary" htmlType="submit">
							Send verification code
						</Button>
						<p>
							<span className="margin-right">
								<span onClick={() => onToggleSignInUp('signIn')}>Sign In</span>
							</span>
						</p>
					</Row>
				</Form>
			</AuthForm>
		</>
	);
};

export default FindPassword;
