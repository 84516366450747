import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Amplify, { Auth as awsAuth } from 'aws-amplify';
import { Layout } from 'antd';

import Auth from './pages/Auth';
import Home from './pages/Home';
import MainHeader from './components/layout/MainHeader';

const { Header, Content } = Layout;

Amplify.configure({
	Auth: {
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
		region: process.env.REACT_APP_REGION,
	},
	Storage: {
		AWSS3: {
			region: process.env.REACT_APP_REGION,
		},
	},
});

const App = () => {
	const authReducer = useSelector((state) => state.authReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		awsAuth
			.currentAuthenticatedUser({
				bypassCache: true,
			})
			.then((user) => {
				dispatch({
					type: 'SIGN_IN',
					payload: user,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleSignOut = async () => {
		try {
			await awsAuth.signOut();
	
			dispatch({ type: 'SIGN_OUT' });
		} catch (err) {
			console.log(err);
		}
	};

	if (authReducer.isSignIn) {
		return (
			<Layout>
				<Header>
					<MainHeader onSignOut={handleSignOut}/>
				</Header>
				<Content>
					<Home />
				</Content>
			</Layout>
		);
	} else {
		return (
			<Router>
				<Route render={() => <Auth />} />
			</Router>
		);
	}
};

export default App;
