import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const SignIn = ({ onSignIn, onToggleSignInUp }) => {
	const onFinish = values => {
		onSignIn(values.username, values.password);
	};

	return (
		<>
			<Form
				name="normal_login"
				className="login-form"
				initialValues={{ remember: true }}
				onFinish={onFinish}
			>
				<Form.Item
					name="username"
					rules={[{ required: true, message: 'Please input your Username!' }]}
				>
					<Input
						prefix={<UserOutlined className="site-form-item-icon" />}
						placeholder="Username"
						type="text"
					/>
				</Form.Item>
				<Form.Item
					name="password"
					rules={[{ required: true, message: 'Please input your Password!' }]}
				>
					<Input
						prefix={<LockOutlined className="site-form-item-icon" />}
						type="password"
						placeholder="Password"
					/>
				</Form.Item>
				<Form.Item>
					<Form.Item name="remember" valuePropName="checked" noStyle>
						<Checkbox>Remember me</Checkbox>
					</Form.Item>
					<a
						className="login-form-forgot"
						href="#none"
						onClick={() => onToggleSignInUp('findPassword')}
					>
						Forgot password
					</a>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="login-form-button"
					>
						Log in
					</Button>
					Or{' '}
					<a href="#none" onClick={() => onToggleSignInUp('signUp')}>
						register now!
					</a>
				</Form.Item>
			</Form>
		</>
	);
};

export default SignIn;
