import React from 'react';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';

const ConfirmSignUp = ({ onConfirmSignUp }) => {
	const onFinish = values => {
		onConfirmSignUp(values.code);
	};

	return (
		<>
			<Form
				name="normal_login"
				className="login-form"
				onFinish={onFinish}
			>
				<Form.Item
					name="code"
					rules={[{ required: true, message: 'Please input your Verification code!' }]}
				>
					<Input
						prefix={<LockOutlined className="site-form-item-icon" />}
						type="text"
						placeholder="Verification code"
					/>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="login-form-button"
					>
						Verification
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default ConfirmSignUp;
