import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';

import { AuthForm } from '../styles/Auth';
import SignIn from '../components/auth/SignIn';
import SignUp from '../components/auth/SignUp';
import ConfirmSignUp from '../components/auth/ConfirmSignUp';
import FindPassword from '../components/auth/FindPassword';

const Auths = () => {
	const authReducer = useSelector((state) => state.authReducer);
	const dispatch = useDispatch();

	const [authType, setAuthType] = useState('signIn');

	useEffect(() => {
		// like didMount
	}, []);

	const handleSignUp = async (username, email, password) => {
		try {
			const user = await Auth.signUp({
				username,
				password,
				attributes: {
					email,
				},
			});

			dispatch({
				type: 'SIGN_UP',
				payload: user.user,
			});

			setAuthType('confirmSignUp');
		} catch (err) {
			console.log(err);
		}
	};

	const handleConfirmSignUp = async (code) => {
		try {
			const user = await Auth.confirmSignUp(authReducer.username, code);

			dispatch({ type: 'CONFIRM_SIGN_UP' });

			setAuthType('signIn');
		} catch (err) {
			console.log(err);
		}
	};

	const handleSignIn = async (username, password) => {
		try {
			const user = await Auth.signIn(username, password);

			console.log(user);

			dispatch({
				type: 'SIGN_IN',
				payload: user,
			});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<AuthForm>
				{authType === 'signIn' && (
					<>
						<SignIn onSignIn={handleSignIn} onToggleSignInUp={setAuthType} />
					</>
				)}
				{authType === 'signUp' && (
					<>
						<SignUp onSignUp={handleSignUp} onToggleSignInUp={setAuthType} />
					</>
				)}
				{authType === 'confirmSignUp' && (
					<>
						<ConfirmSignUp onConfirmSignUp={handleConfirmSignUp} />
					</>
				)}
				{authType === 'findPassword' && (
					<>
						<FindPassword
							onSignIn={handleSignIn}
							onToggleSignInUp={setAuthType}
						/>
					</>
				)}
			</AuthForm>
		</>
	);
};

export default Auths;
